.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* 
.presale_card {
  z-index: 1;
} */


.modal-remainder {
  z-index: 9999;
}

.rm-b-modal {
  padding: 30px;
  border-radius: 10px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  border: 2px solid rgba(255, 255, 255, 0.10);
  /* background: linear-gradient(180deg, #332087 0%, #793AE4 100%); */
  background-image: url('../public/assets/image/popup/download.png');
  background-repeat: no-repeat;
}

.rm-align {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.rm-w-set {
  max-width: 550px;
}

.md-pera {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.rm-main-title {
  color: #FFF;
  text-align: center;
  font-size: 50px;
  font-weight: 550;
  line-height: 100%;
  letter-spacing: -1px;
  text-transform: uppercase;
}

.mb-10 {
  margin-bottom: 10px;
}

.rm-time-line {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #774BE0;
  background: #4C2EA4;
  box-shadow: 0px 4px 20px 0px rgba(172, 142, 242, 0.70);
}

.rm-time-h {
  color: #FFF;
  /* font-size: 60px; */
  font-size: 38px;
  font-weight: 600;
  line-height: 60px;
}

.rm-time-h-pera {
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.rm-jem {
  width: 8px;
  height: 8px;
  aspect-ratio: 1/1;
  background: #FFF;
  opacity: 0.7;
  border-radius: 50%;
}

.rm-modal {
  position: absolute;
  background-color: #00000070;
  backdrop-filter: blur(4px);
}

.desh-hr {
  background-color: #152024;
  height: 1px;
  align-self: stretch;
}

.md-close-btn {
  display: flex;
  padding: 7px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  background: #FFF;
  border: none;
}

.flex-001 {
  flex: 1 1;
}

.md-btn-align {
  height: 40px ;
  background: linear-gradient(92deg, #13D3EE -16.4%, #CE4AD2 110.02%);
  border: none;
  border-radius: 100px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

@media (max-width: 991px) { 

  .rm-b-modal {
      padding: 24px;
  }

  .rm-align {
      gap: 40px;
  }

  .rm-time-h {
      font-size: 48px;
  }

  .rm-time-h-pera {
      font-size: 18px;
  }

  .rm-time-line  {
      padding: 12px;
  }

  .rm-main-title {
      font-size: 42px;
  }
}

@media (max-width: 480px) { 
  .rm-main-title {
      font-size: 32px;
  }

  .rm-align {
      gap: 34px;
  }

  .rm-time-h {
      font-size: 36px;
      line-height: 100%;
  }

  .rm-jem {
      width: 6px;
      height: 6px;    
  }

  .rm-b-modal {
      padding: 18px;
  }

  .rm-time-h-pera {
      font-size: 14px;    
  }
}
